import { css } from '@emotion/core'

export const largeButton = css`
  background-color: #1AB7B1;
  border-radius: 5px;
  color: white;
  padding: 16px 24px;
  text-align: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  border: none;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  outline: none;
  img {
    margin-right: 5px;
  }
  :hover {
    opacity: 0.9;
    transition: background-color 0.3s,opacity 0.3s;
  }
  &[disabled] {
    background-color: #d9d9d9;
    border-color: #d9d9d9;
    color: #464646;
  }
`
