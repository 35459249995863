import React from 'react'

import IconBook from '../images/_iconeQuestoes.svg'
import CheckIcon from '../images/check24x24.svg'
import BgIcon from '../images/bq_logo_icone.svg'
import questionsInfo from '../images/questions_info.png'
import SmallButton from './SmallButtonSite'

import {
  questions,
  sectionHeading,
  title,
  heading_1,
  heading_2,
  heading_3,
  ctaBanner,
  ctaTitle,
  container,
  columns,
  column,
  rightContent
} from './styles/Questions.styles'

const QuestionSite = props => (
  <div css={questions}>
    { false && <div css={sectionHeading}>
      <img src={IconBook} alt='/' />
      <div css={title}>
        + de 50 mil questões classificadas em:
      </div>
    </div>}
    <div css={container}>
      <div css={columns}>
        <div css={column}>
          <img src={questionsInfo} alt='/' />
        </div>
        <div css={column}>
          <p css={heading_1} style={{ marginBottom: 24 }}>Somos uma plataforma de educação, que oferece mais de 90 mil questões para a <span>elaboração de provas, simulados e exercícios.</span></p>
          <p css={heading_1}>Nossa equipe de curadoria especializada faz a triagem das questões antes de aprová-las para o site. Não é simplesmente uma indexação automática de conteúdo.</p>
        </div>
      </div>
    </div>
    <div css={container}>
      <div css={columns}>
        <div css={column}>
          <p css={heading_2}>Aqui você encontra conteúdo com foco em alta performance:</p>
        </div>
        <div css={column}>
          <div css={rightContent}>
            <div>
              <div><img src={CheckIcon} alt='/' /></div>
              ITA
            </div>
            <div>
              <div><img src={CheckIcon} alt='/' /></div>
              IME
            </div>
            <div>
              <div><img src={CheckIcon} alt='/' /></div>
              Olimpíadas de Conhecimento
            </div>
            <div>
              <div><img src={CheckIcon} alt='/' /></div>
              Enem e Vestibularesde todo o Brasil
            </div>
          </div>
        </div>
      </div>
    </div>
    { false && <div css={container}>
      <div css={columns}>
        <div css={column}>
          <p css={heading_3} style={{ color: '#4DAAFF', fontWeight: 'bold' }}>E tem mais: aqui seus créditos nunca expiram!</p>
        </div>
        <div css={column}>
          <img src={BgIcon} alt='/' />
        </div>
      </div>
    </div>}
    { false &&<div css={ctaBanner}>
      <div css={ctaTitle}>
        Veja exemplo de prova<br /> gerada pelo Banco de Questões
      </div>
      <div style={{ textAlign: 'center' }}>
        <a
          href='/'
        >
          <SmallButton>Confira a Prova</SmallButton>
        </a>
      </div>
    </div>}
  </div>
)

export default QuestionSite
