import axios from 'axios'

const port = process.env.GATSBY_DG_PORT || 3000

const backendHost = {
  'production': 'https://datagateway.fractaltecnologia.com.br',
  'staging': 'https://staging.datagateway.fractaltecnologia.com.br',
  'development': 'https://staging.datagateway.fractaltecnologia.com.br',
  'local': `http://localhost:${port}`,
  'demo': 'https://demo.datagateway.fractaltecnologia.com.br'
}

const setAPIEnv = env =>
  process.env.GATSBY_SERVER_ENV ? backendHost[process.env.GATSBY_SERVER_ENV] : backendHost['development']

const apiVersion = 'v1'

const instance = axios.create({
  baseURL: `${setAPIEnv()}/api/${apiVersion}`,
  defaults: {
    timeout: 60000
  }
})

export const host = backendHost

const appPaths = {
  'staging': 'https://stag-app.bancodequestoes.com',
  'production': 'https://app.bancodequestoes.com'
}

export const currentAppPath = appPaths[process.env.GATSBY_SERVER_ENV]

export default instance
