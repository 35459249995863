import { css } from '@emotion/core'

export const pricing = css`
  margin: 80px 0;
  background: #fff;
`
export const container = css`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  flex-direction: column;
  height: auto;
`
export const content = css`
  text-align: center;
`
export const title = css`
  ${content}
  color: #222222;
  font-size: 36px;
  font-weight: bold;
  line-height: 49px;
  text-align: center;
`
export const subTitle = css`
  ${content}
  color: #494949;
  font-size: 14px;
  line-height: 23px;
  span {
    font-weight: bold;
  }
`
export const rangeSlider = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  height: auto;
`
export const numbers = css`
  color: #6F9CEB;
  font-size: 12px;
  line-height: 17px;
  margin-right: 30px;
`
export const priceDescription = css`
  color: #005099;
  font-size: 36px;
  font-weight: bold;
  line-height: 49px;
  margin-bottom: 10px;
  height: auto;
  text-align: center;
  span {
    font-size: 16px;
    text-transform: uppercase;
    color: #0770CF;
  }
  @media (max-width: 475px) {
    line-height: 35px;
  }
`
export const creditDescription = css`
  ${content}
  color: #0770CF;
  font-size: 12px;
  font-style: italic;
  line-height: 17px;
  margin-top: 8px;
  margin-bottom: 8px;
`
export const termsUse = css`
  ${content}
  color: #CCCCCC;
  font-size: 14px;
  line-height: 19px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 24px;
`
export const btnSignIt = css`
  margin-top: 30px;
`
export const slider = css`
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;
  width: 815px;
`
