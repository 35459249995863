import React, { Fragment } from 'react'
import Logo from '../images/logo_bq.svg'
import { Link } from 'gatsby'
// import SSOLink from './SSOLink'
import ScrollAnim from 'rc-scroll-anim'
// import { currentAppPath } from '../utils/http'
import { MenuContext } from '../pages/index'
import ResponsiveMenu from './navbar/ResponsiveNavbar'
import AddCircleIcon from '../images/add_circle2.svg'
import IconLogin from '../images/icon_login.svg'
// import ArrowIcon from '../images/seta_direita.svg'
import OpenIcon from '../images/open_menu.svg'
import CloseIcon from '../images/close_menu.svg'

import {
  navBar,
  container,
  logo,
  navBarMenu,
  createAccount,
  LoginBtn,
  // GoToDashboard,
  navMenu,
  mobileMenu
} from './styles/Navbar.styles'

const NavbarSite = props => (
  <MenuContext.Consumer>
    {({ visible, setVisible }) => {
      return (
        <div css={navBar}>
          <div css={container}>
            <div css={logo} title='Banco de Questões'>
              <ScrollAnim.Link
                to='top'
                offsetTop={100}
                onFocus={() => setVisible(false)}
              >
                <Link to={props.isLogged ? '/home' : '/'}><img src={Logo} alt='Logo' style={{ width: 220 }} /></Link>
              </ScrollAnim.Link>
            </div>
            <div css={navMenu}
              style={{
                display: props.isMenuDisabled ? 'none' : 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                height: '100%'
              }}>
              <ResponsiveMenu
                menuOpenButton={<img src={OpenIcon} alt='/' />}
                menuCloseButton={<img src={CloseIcon} alt='/' />}
                changeMenuOn='960px'
                largeMenuClassName={navBarMenu}
                smallMenuClassName={mobileMenu}
                menuVisible={visible}
                setMenu={setVisible}
                menu={
                  <Fragment>
                    <ScrollAnim.Link
                      to='questions'
                      offsetTop={100}
                      onFocus={() => setVisible(false)}
                    >
                      Sobre
                    </ScrollAnim.Link>
                    { false && <ScrollAnim.Link
                      to='functionalities'
                      offsetTop={100}
                      onFocus={() => setVisible(false)}
                    >
                      Alunos
                    </ScrollAnim.Link>}
                    <ScrollAnim.Link
                      to='teachers'
                      offsetTop={100}
                      onFocus={() => setVisible(false)}
                    >
                      Professores
                    </ScrollAnim.Link>
                    <ScrollAnim.Link
                      to='schools'
                      offsetTop={100}
                      onFocus={() => setVisible(false)}
                    >
                      Escolas
                    </ScrollAnim.Link>
                    { false &&<ScrollAnim.Link
                      to='pricing'
                      offsetTop={100}
                      onFocus={() => setVisible(false)}
                    >
                      Créditos
                    </ScrollAnim.Link> }
                    <Fragment>
                      { false &&<a
                        href='https://fractalid.fractaltecnologia.com.br/signup?app_id=17&url_redirect=https%3A%2F%2Fapp.bancodequestoes.com%2Flogin'
                        appId='17'
                        css={createAccount}
                      >
                        <Fragment><img src={AddCircleIcon} alt='/' /> Crie Sua Conta</Fragment>
                      </a>}
                      <a
                        appId='17'
                        href='https://fractalid.fractaltecnologia.com.br/?app_id=17&url_redirect=https%3A%2F%2Fapp.bancodequestoes.com%2Flogin'
                        css={LoginBtn}
                      >
                        <img src={IconLogin} alt='/' /> Faça seu Login
                      </a>
                    </Fragment>
                  </Fragment>
                }
              />
            </div>
          </div>
        </div>
      )
    }}
  </MenuContext.Consumer>
)

export default NavbarSite
