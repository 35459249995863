import PropTypes from 'prop-types'

export default {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.array,
    PropTypes.string,
    PropTypes.number
  ]).isRequired
}
