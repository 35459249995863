import React, { Fragment } from 'react'
// import { Link } from 'gatsby'
// import SSOLink from './SSOLink'
import { Modal } from 'antd'
// import { currentAppPath } from '../utils/http'
// import { RawLink } from '@fractaltecnologia/fractal-lib-auth'
import Facebook from '../images/social_icons/facebook.svg'
import Instagram from '../images/social_icons/instagram.svg'
import AddCircleIcon from '../images/add_circle2.svg'
import IconLogin from '../images/icon_login.svg'
import CreditCard from '../images/paymentMethods/creditCard.png'
import Ticket from '../images/paymentMethods/ticket.png'
import AccountDeposit from '../images/paymentMethods/accountDeposit.png'
import OnlineDebit from '../images/paymentMethods/onlineDebit.png'
import PagseguroAccount from '../images/paymentMethods/pagseguroAccount.png'
import Button from './LargeButtonSite'

import {
  footer,
  title,
  subTitle,
  gridContainer,
  gridItem,
  gridItemA,
  gridItemB
} from './styles/Footer.styles'

class FooterSite extends React.Component {
  state = {
    visible: false
  }

  render () {
    return (
      <React.Fragment>
        <div css={footer}>
          <div css={gridContainer}>
            <div css={gridItemA}>
              <div css={gridItem}>
                <div>
                  <a
                    href='https://www.facebook.com/Banco-de-Quest%C3%B5es-621229591726851/'
                  >
                    <img src={Facebook} alt='/' />
                  </a>
                  <a
                    href='https://www.instagram.com/bancoquestoes/'
                    style={{ marginLeft: 10, marginRight: 10 }}
                  >
                    <img src={Instagram} alt='/' />
                  </a>
                </div>
                {/* { false && <div css={linkTo} style={{ display: 'flex', flexDirection: 'column', marginTop: 24 }}>
                  <div onClick={() => this.showModal()}>
                    Termos de Uso
                  </div>
                </div>} */}
              </div>
              <div css={gridItem}>
                <Fragment>
                  <Button 
                    href='https://fractalid.fractaltecnologia.com.br/?app_id=17&url_redirect=https%3A%2F%2Fapp.bancodequestoes.com%2Flogin'
                  >
                    <Fragment><img src={AddCircleIcon} alt='/' /> Crie Sua Conta</Fragment>
                  </Button>
                  <div style={{ marginTop: 10 }}>
                    <Button
                      href='https://fractalid.fractaltecnologia.com.br/?app_id=17&url_redirect=https%3A%2F%2Fapp.bancodequestoes.com%2Flogin'
                      backgroundColor='#4285F4'
                    >
                      <img src={IconLogin} alt='/' /> Faça seu Login
                    </Button>
                  </div>
                </Fragment>
              </div>
            </div>
            <div css={gridItemB}>
              <div css={gridItem}>
                <div css={title}>Formas de Pagamento</div>
                <div css={subTitle} style={{ marginTop: 10 }}>Cartão de Crédito</div>
                <img src={CreditCard} alt='/' style={{ marginTop: 2 }} title='Cartões de crédito aceitos' />
              </div>
              <div css={gridItem}>
                <div css={subTitle} style={{ marginTop: 10 }}>Boleto</div>
                <img src={Ticket} alt='/' style={{ marginTop: 2 }} title='Boleto' />
                <div css={subTitle}>Débito Online</div>
                <img src={OnlineDebit} alt='/' style={{ marginTop: 2 }} title='Débito Online: Bradesco, Banco do Brasil, Itaú, Banrisul' />
                <div css={subTitle}>Depósito em Conta</div>
                <img src={AccountDeposit} alt='/' style={{ marginTop: 2 }} title='Banco do Brasil' />
                <div css={subTitle}>Saldo Conta Pagseguro</div>
                <img src={PagseguroAccount} alt='/' style={{ marginTop: 2 }} title='Saldo Conta Pagseguro' />
              </div>
            </div>
          </div>
        </div>
        <Modal
          title='TERMOS DE USO'
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <p>Some contents...</p>
        </Modal>
      </React.Fragment>
    )
  }

  showModal = () => {
    this.setState({
      visible: true
    })
  }

  handleOk = (e) => {
    this.setState({
      visible: false
    })
  }

  handleCancel = (e) => {
    this.setState({
      visible: false
    })
  }
}

export default FooterSite
