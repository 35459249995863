import { css } from '@emotion/core'
import BgSchool from '../../images/bg_school.png'

export const container = css`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  background: #fff;
`
export const schools = css`
  background: url(${BgSchool}) no-repeat;
  width: 100%;
  height: 500px;
  background-position: center;
`
export const content = css`
  margin: 60px 0 0 100px;
`
export const title = css`
  ${content}
  color: #FFFFFF;
  font-size: 36px;
  font-weight: bold;
  line-height: 49px;
  @media (min-width: 260px) and (max-width: 600px) {
    margin: 80px 0 0 15px;
  }
  @media (min-width: 601px) and (max-width: 800px) {
    margin: 80px 0 0 25px;
  }
`
export const description = css`
  margin: 25px 0 0 100px;
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  @media (min-width: 260px) and (max-width: 600px) {
    margin: 25px 0 0 15px;
    font-size: 18px;
    line-height: 25px;
  }
  @media (min-width: 601px) and (max-width: 800px) {
    margin: 25px 0 0 25px;
  }
  cursor: default;
  p {
    margin-bottom: 16px;
    display: flex;
  }
  span {
    color: #FFD166;
  }
  img {
    align-self: flex-start;
    margin-top: 10px;
    margin-right: 10px;
    width: 35px;
  }
`
