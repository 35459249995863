import { css } from '@emotion/core'

export const navBar = css`
  display: block;
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  min-height: 100px;
  max-height: 100px;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 9px 0 rgba(0,0,0,0.30);
`
export const container = css`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1159px;
  height: 100%;
`
export const logo = css`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (min-width: 0px) and (max-width: 960px) {
    margin-left: 10px;
  }
`
export const navBarMenu = css`
  display: flex;
  height: 100%;
  min-height: 80px;
  color: #494949;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 19px;
  align-items: center;
  cursor: pointer;
  div:not(:last-child) {
    margin-right: 24px;
  }
  div:hover {
    color: #0770CF;
    transition: color 0.25s ease-out;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    display: none
  }
  @media (min-width: 0px) and (max-width: 960px) {
    background: #fff;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
  }
`
export const btn = css`
  padding: .5rem 1rem;
  color: #fff;
  height: 100%;
  display: flex;
  align-items: flex-start;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  img {
    margin-bottom: 5px;
  }
`
export const createAccount = css`
  ${btn}
  background-color: #1AB7B1;
  border-radius: 0 0 0 5px;
  margin-right: 0px !important;
  :hover {
    opacity: 0.9;
    color: #fff;
    transition: background-color 0.3s,opacity 0.3s;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    border-radius: 0px;
  }
`
export const LoginBtn = css`
  ${btn}
  background-color: #4285F4;
  border-radius: 0 0 5px 0;
  :hover {
    background-color: #6F9CEB;
    color: #fff;
    transition: background-color 0.3s,opacity 0.3s;
  }
  @media (min-width: 0px) and (max-width: 960px) {
    border-radius: 0px;
  }
`
export const GoToDashboard = css`
  ${btn}
  background-color: #4285F4;
  border-radius: 0 0 5px 0;
  :hover {
    background-color: #6F9CEB;
    color: #fff;
    transition: background-color 0.3s,opacity 0.3s;
  }
`
export const navMenu = css`
  & > div {
    height: 100%;
  }
`
export const mobileMenu = css`
  & > div:nth-child(1) {
    display: flex;
    margin: 25px;
    cursor: pointer;
  }
  & > div:nth-child(2) {
    background: #fff;
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    color: #494949;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 19px;
    border-top: 1px solid #efefef;
    cursor: pointer;
    div {
      padding: 10px 0;
    }
  }
`
