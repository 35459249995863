import React from 'react'

import ImgSchool from '../images/img_escola.jpg'

import {
  teachers, 
  columns, 
  column, 
  title, 
  leftContent
} from './styles/Schools.styles'

const SchoolsSite = props => (
  <div css={teachers}>
    <div css={columns}>
      <div css={column}>
        <div css={leftContent}>
          <div css={title}>Escolas</div>
          <p>As instituições de ensino cada vez mais precisam investir em soluções inovadoras para atrair e reter seus professores e, também, para melhor gerenciar a qualidade de ensino que eles entregam no dia a dia.</p>
          <p>Ao comprar a solução do Banco de Questões e personalizá-la para sua marca, a escola valoriza o trabalho e tempo de seus professores e consegue centralizar e automatizar  dados referentes as provas e exercícios aplicados em sala de aula e acompanhar o desempenho de seus profissionais.</p>
          <p>Aos professores, é possível organizar listas de provas e exercícios, além de armazená-las no banco de dados, para acesso posterior. Com isso, conseguem monitorar quais provas foram dadas em quais datas, para quais turmas, com qual nível de dificuldade, entre outros.</p>
          <p>Para as escolas é possível monitorar a adesão dos professores ao recurso oferecido e entender como eles o estão utilizando. Isso possibilita entender e avaliar a performance de cada professor e/ou disciplina nas provas e exercícios correntes.</p>
        </div>
      </div>
      <div css={column}>
        <img src={ImgSchool} alt='/' />
      </div>
    </div>
  </div>

)

export default SchoolsSite