import React from 'react'
import { Spin, Icon } from 'antd'
import propTypes from 'prop-types'

const Spinner = props => {
  const {
    iconSize,
    children,
    style,
    iconStyle,
    ...rest
  } = props

  const customIcon = <Icon
    type='loading'
    style={{
      ...iconStyle,
      fontSize: iconSize || 24,
      width: iconSize + 2 || 26,
      height: iconSize + 2 || 26,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
    spin
  />

  return (
    <Spin indicator={customIcon} style={{ ...style }} {...rest}>{children}</Spin>
  )
}

Spinner.propTypes = {
  iconSize: propTypes.number,
  iconStyle: propTypes.object
}

export default Spinner
