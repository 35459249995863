import React from 'react'

import ImgTeacher from '../images/img_teacher.png'

import {
  teachers, columns, column, title, leftContent
} from './styles/Teachers.styles'

const QuestionSite = props => (
  <div css={teachers}>
    <div css={columns}>
      <div css={column}>
        <div css={leftContent}>
          <div css={title}>Professores</div>
          <p>Sabemos dos desafios na rotina de um professor e, foi com isso em mente, que criamos essa plataforma. Nosso objetivo é oferecer uma ferramenta para otimizar seu tempo na elaboração de provas e exercícios, para que possa investi-lo em pesquisas, preparação de aulas mais interativas e projetos para aumentar o engajamento em sala de aula. Melhorando ambas as performances: a sua como profissional e a de seus alunos.</p>
          <p>O banco de questões oferece conteúdo selecionado e de alto nível. Com mais de 50 filtros classificados em disciplina, tema, subtema, concurso, região, entre outros, crie materiais focados na necessidade de suas turmas em pouco tempo</p>
        </div>
      </div>
      <div css={column}>
        <img src={ImgTeacher} alt='/' />
      </div>
    </div>
  </div>

)

export default QuestionSite