import { css } from '@emotion/core'
import BgDownloadTest from '../../images/bg_download_prova.png'

export const questions = css`
  position: relative;
  padding: 2rem 0;
  z-index: 1;
  background: #fff;
`
export const sectionHeading = css`
  text-align: center;
  font-family: 'Open Sans';
  padding-bottom: 20px;
`
export const title = css`
  color: #0770CF;
  font-size: 48px;
  font-weight: 800;
  line-height: 55px;
  font-family: 'Poppins', sans-serif;

  @media (max-width: 425px) and (min-width: 260px) {
    font-size: 35px;
    line-height: 40px;
  }
`
export const subTitle = css`
  color: #222222;
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
`
export const knowledgeAreaTitle = css`
  color: #0770CF;
  font-size: 24px;
  font-weight: bold;
  line-height: 33px;
  margin-bottom: 24px;
  margin-top: 24px;
  text-align: center;
`
export const txtA = css`
  color: #0770CF;	
  font-size: 100px;
  font-weight: bold;
  line-height: 150px;
  text-align: justify;
`
export const txtB = css`
  color: #005099;
  font-size: 100px;
  font-weight: bold;
  line-height: 150px;
  text-align: justify;
`
export const txtC = css`
  color: #FFD166;
  font-size: 66px;
  font-weight: 300;
  line-height: 99px;
  text-align: justify;
`
export const subjectsBox = css`
  &:not(:last-child):after {
    margin: 24px 0;
    content: '';
    border: 1px solid #EFEFEF;
    width: 206px;
    display: inline-block;
  }
  span {
    color: #494949;
    font-size: 18px;
    line-height: 24px;
  }
  p {
    color: #494949;
    font-size: 24px;
    font-weight: bold;
    line-height: 33px;
  }
`
export const ctaBanner = css`
  background: url(${BgDownloadTest}) no-repeat 100% 100%;
  height: 175px;
  width: 100%;
  background-size: cover;
`
export const ctaTitle = css`
  color: #FFFFFF;
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  text-align: center;
  padding: 32px 0 16px 0;
  @media (min-width: 260px) and (max-width: 425px) {
    padding: 16px 0 16px 0;
  }
`
export const boxContent = css`
  width: 359px;
  height: 360px;
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 11px 10px 0 rgba(0,0,0,0.09);
  padding: 32px 0 32px 0;
  transition: all .3s ease-out;
  cursor: default;
  :hover {
    -webkit-box-shadow: 0 0 30px 0px rgba(0,0,0,0.18);
  }
  @media (min-width: 260px) and (max-width: 425px)  {
    width: 310px;
    padding: 21px;
    h2 {
      font-size: 22px;
    }
  }
`
export const questionsContent = css`
  color: #494949;
  font-size: 16px;
  line-height: 22px;
  text-align: left;
  margin-left: 32px;
  img {
    margin-right: 8px;
  }
  p {
    margin-bottom: 16px;
  }
`
export const container = css`
  padding: 5px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 1130px;
  &:not(:last-child):after {
    margin: 24px 0;
    content: '';
    border: 1px solid #EFEFEF;
    width: 100%;
    display: inline-block;
  }
`
export const columns = css`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 5px 0;
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }  
  }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
`
export const column = css`
  flex: 1;
  background-color: #FFFFFF;
  /*box-shadow: 0 11px 10px 0 rgba(0,0,0,0.09);
  border-radius: 5px;*/
  transition: all .3s ease-out;
  cursor: default;
  margin: 2px;
  padding: 10px;
  img {
    width: 310px;
  }
  &:first-child { 
    margin-left: 0;
    margin-right: 20px; 
  }
  &:last-child { 
    margin-left: 20px;
    margin-right: 0; 
  }
  /*:hover {
    -webkit-box-shadow: 0 0 30px 0px rgba(0,0,0,0.18);
  }*/
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }  
  }
  @media screen and (max-width: 680px) {
    &:first-child {
      margin-right: 0; 
    }
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
`
export const heading_1 = css`
  color: #3D3E40;
  font-size: 20px;
  letter-spacing: 0.7px;
  line-height: 38px;
  span {
    font-weight: bold;
  }
`
export const heading_2 = css`
  color: #4DAAFF !important;
  font-size: 32px;
  font-weight: bold;
  line-height: 38px;
`
export const heading_3 = css`
  color: #3D3E40 ;
  font-size: 18px;
  letter-spacing: 0.53px;
  line-height: 30px;
`
export const rightContent = css`
  display: flex;
  color: #494949;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 22px;
  align-items: center;
  text-align: center;
  div:not(:last-child) {
    margin-right: 60px;
  }
  img {
    width: 54px;
    margin-bottom: 30px;
  }
`
