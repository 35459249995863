import { css } from '@emotion/core'

export const btn = css`
  background-color: #1AB7B1;
  border-radius: 5px;
  color: white;
  padding: 8px 16px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  border: none;
  font-family: 'Poppins',sans-serif;
  cursor: pointer;
  outline: none;
  :hover {
    opacity: 0.9;
    transition: background-color 0.3s,opacity 0.3s;
  }
`
