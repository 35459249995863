import React from 'react'

import Tabs from './TabsSite'
import PropTypesUtil from '../utils/prop-types'
import ExerciseList from '../images/monteSuaLista.png'
import DownloadLists from '../images/facaDownload.png'
import Storedlists from '../images/listasArmazenadas.png'

import {
  differentials,
  container,
  title,
  subTitle,
  tabImage,
  Pane
} from './styles/Differentials.styles'

const tabs = [{
  name: 'Monte sua lista de exercícios ou provas.',
  img: ExerciseList
}, {
  name: 'Faça download de suas listas em .DOCX',
  img: DownloadLists

}, {
  name: 'Suas listas ficam armazenadas em nosso banco',
  img: Storedlists
}]

Pane.propTypes = {
  children: PropTypesUtil.children
}

const DifferentialsSite = props => {
  return (
    <div css={differentials}>
      <div css={container}>
        <div css={title}>Funcionalidades</div>
        {false && <div css={subTitle}>Everything you need to bring ideas to life - wireframe, prototype<br /> and handoff to developers in one place</div>}
        <Tabs selected={props.firstSelect || 0}>
          {tabs.map((tab, key) => (
              <Pane key={key} label={tab.name}>
                <img css={tabImage} src={tab.img} alt={tab.name} />
              </Pane>
            )
          )}
        </Tabs>
      </div>
    </div>
  )
}

export default DifferentialsSite
