import { css } from '@emotion/core'

export const rangeWrapper = css`
  width: 100%;
  padding-bottom: 10px;
  user-select: none;
  /**
  * Rangeslider
  */
  .rangeslider {
    margin: 20px auto;
    position: relative;
    background-color: #D9E6FD;
    -ms-touch-action: none;
    touch-action: none;
    max-width: 815px;
    width: 100%;
  }
  .rangeslider,
  .rangeslider .rangeslider__fill {
    display: flex;
    flex-direction: column;
  }
  .rangeslider .rangeslider__handle {
    background: #fff;
    cursor: pointer;
    display: inline-block;
    position: absolute;
  }
  .rangeslider .rangeslider__handle .rangeslider__active {
    opacity: 1;
  }
  .rangeslider .rangeslider__handle-tooltip {
    width: 40px;
    height: 40px;
    text-align: center;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    font-weight: normal;
    font-size: 14px;
    transition: all 100ms ease-in;
    border-radius: 4px;
    display: inline-block;
    color: white;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
  }
  .rangeslider .rangeslider__handle-tooltip span {
    margin-top: 12px;
    display: inline-block;
    line-height: 100%;
  }
  .rangeslider .rangeslider__handle-tooltip:after {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
  }
  /**
  * Rangeslider - Horizontal slider
  */
  .rangeslider-horizontal {
    height: 24px;
    border-radius: 12px;
  }
  .rangeslider-horizontal .rangeslider__fill {
    height: 100%;
    background-color: #005099;
    border-radius: 10px;
    top: 0;
    cursor: pointer;
    transition: width 0.1s ease-out;
  }
  .rangeslider-horizontal .rangeslider__handle {
    width: 36px;
    height: 36px;
    border-radius: 30px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    box-shadow: -1px 2px 6px 0 rgba(0,0,0,0.18);
    transition: left 0.1s ease-out;
  }
  .rangeslider-horizontal .rangeslider__handle:after {
    content: ' ';
    position: absolute;
    width: 24px;
    height: 24px;
    top: 6px;
    left: 6px;
    border-radius: 50%;
    background: linear-gradient(90deg, #4285F4 0%, #6F9CEB 100%);
  }
  .rangeslider-horizontal .rangeslider__handle-tooltip {
    top: -55px;
  }
  .rangeslider-horizontal .rangeslider__handle-tooltip:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(0, 0, 0, 0.8);
    left: 50%;
    bottom: -8px;
    transform: translate3d(-50%, 0, 0);
  }
  /**
  * Rangeslider - Reverse
  */
  .rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
    right: 0;
  }
  .rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
    top: 0;
    bottom: inherit;
  }
  /**
  * Rangeslider - Labels
  */
  .rangeslider__labels {
    position: relative;
  }
  .rangeslider-vertical .rangeslider__labels {
    position: relative;
    list-style-type: none;
    margin: 0 0 0 24px;
    padding: 0;
    text-align: left;
    width: 250px;
    height: 100%;
    left: 10px;
  }
  .rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
    position: absolute;
    transform: translate3d(0, -50%, 0);
  }
  .rangeslider-vertical .rangeslider__labels .rangeslider__label-item:::before {
    content: '';
    width: 10px;
    height: 2px;
    background: black;
    position: absolute;
    left: -14px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
  }
  .rangeslider__labels .rangeslider__label-item {
    position: absolute;
    color: #005099;
    font-size: 12px;
    line-height: 17px;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    transform: translate3d(-50%, 0, 0);
  }
  @media (max-width: 475px) {
    .rangeslider {
      width: 300px;
    }
    .rangeslider__labels .rangeslider__label-item {
      display: none;
    }
  }
  @media (min-width: 476px) and (max-width: 600px) {
    .rangeslider {
      width: 420px;
    }
    .rangeslider__labels .rangeslider__label-item {
      display: none;
    }
  }
  @media (min-width: 601px) and (max-width: 750px) {
    .rangeslider {
      width: 550px;
    }
  }
  @media (min-width: 751px) and (max-width: 900px) {
    .rangeslider {
      width: 700px;
    }
  }
`
