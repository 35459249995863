import React from 'react'
import Banner from './BannerItemSite'
import BgAbout from '../images/bg_about_.png'
import BgTeachers from '../images/bg_teachers_.png'
import BgSchool from '../images/bg_schools_.png'
import SmallButton from './LargeButtonSite'

const carouselSlidesData = [
  {
    title: 'Conteúdo de alto nível para turbinar sua performance',
    imageBanner: BgAbout,
    button: <SmallButton>Saiba Mais</SmallButton>
  },
  {
    title: 'Ganhe tempo e qualidade na elaboração de provas e exercícios',
    imageBanner: BgTeachers,
    button: <SmallButton>Saiba Mais</SmallButton>
  },
  {
    title: 'Ofereça inovação digital de forma simples e com resultados de ponta',
    imageBanner: BgSchool,
    button: <SmallButton>Saiba Mais</SmallButton>
  }
]

const HeroBannerSite = props => (
  <Banner
    slides={carouselSlidesData}
  />
)

export default HeroBannerSite
