import React, { Component } from 'react'
import { Element } from 'rc-scroll-anim'
import { Global } from '@emotion/core'
import NavBar from '../components/NavbarSite'
import Banner from '../components/HeroBannerSite.js'
import Questions from '../components/QuestionsSite'
import Teachers from '../components/TeachersSite'
import Schools from '../components/SchoolsSite'
import Differentials from '../components/DifferentialsSite'
import Pricing from '../components/PricingSite'
import ForSchools from '../components/ForSchoolsSite'
import Footer from '../components/FooterSite'
import SEO from "../components/seo"

import { globalStyles } from '../components/layout.styles'

export const MenuContext = React.createContext({
  visible: false,
  setVisible: () => {}
})

class SitePage extends Component {
  constructor (props) {
    super(props)
    this.setVisible = (value) => {
      this.setState(({ visible: value }))
    }

    this.state = {
      visible: false,
      setVisible: this.setVisible
    }
  }

  render () {
    return (
      <div>
        <Global styles={globalStyles} />
        <SEO title="Aqui você encontra conteúdo com foco em alta performance" />
        <MenuContext.Provider value={this.state}>
          <Element
            id='top'
          >
            <NavBar />
          </Element>
          <Banner />
          <Element
            id='questions'
          >
            <Questions />
          </Element>
          <Element
            id='teachers'
          >
            <Teachers />
          </Element>
          <Element
            id='functionalities'
          >
            <Differentials />
          </Element>
          <Element
            id='schools'
          >
            <Schools />
          </Element>
          { false &&<Element
            id='pricing'
          >
            <Pricing />
          </Element>}
          { false && <Element
            id='forschools'
          >
            <ForSchools />
          </Element>}
          { false && <Footer /> }
        </MenuContext.Provider>
      </div>
    )
  }
}

export default SitePage
