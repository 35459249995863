import { css } from '@emotion/core'
import BgSchools from '../../images/bg_escolas.png'

export const schools = css`
  position: relative;
  padding: 2rem 0;
  z-index: 1;
  background: #fff;
  width: 100%;
`
export const columns = css`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 5px 0;
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }  
  }
  @media screen and (max-width: 680px) {
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
`
export const column = css`
  flex: 1;
  background-color: #FFFFFF;
  /*box-shadow: 0 11px 10px 0 rgba(0,0,0,0.09);
  border-radius: 5px;*/
  transition: all .3s ease-out;
  cursor: default;
  padding: 10px 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:first-child { 
    margin-left: 0;
    margin-right: 0; 
  }
  &:last-child { 
    margin-left: 0;
    margin-right: 0; 
  }
  /*:hover {
    -webkit-box-shadow: 0 0 30px 0px rgba(0,0,0,0.18);
  }*/
  @media screen and (max-width: 980px) {
    margin-bottom: 5px;
    flex-basis: 40%;
    &:nth-last-child(2) {
      margin-right: 0;
    }
    &:last-child {
      flex-basis: 100%;
      margin: 0;
    }  
  }
  @media screen and (max-width: 680px) {
    &:first-child {
      margin-right: 0; 
    }
    flex-basis: 100%;
    margin: 0 0 5px 0;
  }
`
export const leftContent = css`
  background: #0770CF;
  padding: 55px 100px;
  p {
    color: #FFFFFF;	
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 24px;
  }
`
export const rightContent = css`
  padding: 55px 100px;
  height: 100%;
  background: url(${BgSchools}) no-repeat;
`
export const title = css`
  color: #fff;
  font-size: 36px;
  font-weight: 800;
  line-height: 55px;
  text-align: justify;
  margin-bottom: 32px;
`

export const teachers = css``
