import React from 'react'
import Spinner from './spinner/Spinner'

import {
  largeButton
} from './styles/LargeButton.styles'

const LargeButtonSite = ({ children, backgroundColor, padding, style, loading, ...rest }) => (
  <button
    {...rest}
    css={largeButton}
    style={{
      cursor: loading ? 'not-allowed' : 'pointer',
      backgroundColor,
      padding,
      ...style
    }}
  >
    { children }
    {loading && <Spinner iconSize={16} iconStyle={{ color: 'white', marginLeft: 10 }} />}
  </button>
)

export default LargeButtonSite
