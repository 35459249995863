import { css } from '@emotion/core'

export const footer = css`
  margin-top: 32px;
  margin-bottom: 32px;
  background: #fff;
`
export const container = css`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
`
export const linkTo = css`
  display: flex;
  flex-direction: column;
  color: #494949;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
  cursor: pointer;
  a {
    margin-bottom: 8px;
  }
`
export const logo = css`
  background: #F2F2F2;
  align-content: center;
  padding: 10px 0;
  text-align: center;
`
export const title = css`
  color: #222222;
  font-size: 14px;
  font-weight: bold;
  line-height: 19px;
`
export const subTitle = css`
  color: #494949;
  font-size: 12px;
  line-height: 17px;
`
export const gridContainer = css`
  display: grid;
  grid-template-columns: auto auto;
  max-width: 1130px;
  margin: 0 auto;
  @media (min-width: 260px) and (max-width: 425px) {
    grid-template-columns: auto;
  }
  @media (min-width: 426px) and (max-width: 865px) {
    grid-template-columns: auto;
  }
`
export const gridItem = css`
  padding: 16px;
  @media (min-width: 260px) and (max-width: 425px) {
    button {
      width: 100%;
    }
  }
`
export const gridItemA = css`
  ${gridContainer};
  width: 100%;
  @media (min-width: 426px) and (max-width: 865px) {
    grid-template-columns: auto auto;
  }
`
export const gridItemB = css`
  ${gridContainer};
  width: 100%;
  @media (min-width: 426px) and (max-width: 865px) {
    grid-template-columns: auto auto;
  }
`
