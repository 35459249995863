import React from 'react'

import {
  row,
  tabsLabel,
  tabsContent
} from './styles/Differentials.styles'

class TabsSite extends React.Component {
  constructor (props) {
    super(props)
    this.state = { selected: this.props.selected }
  }

  render () {
    return (
      <div css={row}>
        {this.renderTitles()}
        <div css={tabsContent}>
          {this.props.children[this.state.selected]}
        </div>
      </div>
    )
  }

  renderTitles () {
    const labels = (child, idx) => {
      let activeClass = (this.state.selected === idx ? 'is-active' : '')
      return (
        <li key={idx} aria-controls={`panel${idx}`}>
          <a className={activeClass} onClick={e => this.onClick(idx, e)} href='/'>
            {child.props.label}
          </a>
        </li>
      )
    }
    return (
      <ul css={tabsLabel}>
        {this.props.children.map(labels)}
      </ul>
    )
  }

  onClick (index, event) {
    event.preventDefault()
    this.setState({
      selected: index
    })
  }
}

export default TabsSite
