import { css } from '@emotion/core'
import styled from '@emotion/styled'
import BgFunctionality from '../../images/bg_video_funcionalidades.png'

export const differentials = css`
  margin-top: 32px;
  margin-bottom: 32px;
  background: #fff;
`
export const container = css`
  display: flex;
  width: 100%;
  margin: 0 auto;
  max-width: 1130px;
  justify-content: center;
  flex-direction: column;
  height: auto;
`
export const title = css`
  color: #222222;
  font-size: 36px;
  font-weight: bold;
  line-height: 49px;
  text-align: left;
  margin-bottom: 32px;
`
export const subTitle = css`
  color: #494949;
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  margin-bottom: 24px;
`
export const row = css`
  display: flex;
  @media (min-width: 260px) and (max-width: 767px) {
    flex-direction: column;
  }
`
export const tabsLabel = css`
  width: 25%;
  height: 70vh;
  li {
    color: #222222;
    font-size: 18px;
    line-height: 20px;
    a {
      color: #222222;
      :hover {
        color: #0770CF;
        font-weight: bold;
      }
    }
      &:not(:last-child):after {
      margin: 24px 0;
      content: '';
      border: 1px solid #EFEFEF;
      width: 100%;
      display: inline-block;
    }
    :hover {
      color: #0770CF;
    }
  }
  .is-active {
    color: #0770CF;
    font-weight: bold;
  }
  @media (min-width: 260px) and (max-width: 767px) {
    width: auto;
    height: auto;
    li {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    width: auto;
    height: auto;
    li {
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`
export const tabsContent = css`
  margin-left: 34px;
  background: url(${BgFunctionality}) no-repeat;
  width: 70%;
  overflow: hidden;
  background-size: contain;
  height: auto;
  img {
    width: 100%;
  }
  @media (min-width: 260px) and (max-width: 767px) {
    width: 100%;
    margin-left: 0px;
    margin-top: 24px;
    img {
      width: 100%;
      margin-left: 0;
      margin-top: 0;
    }
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    width: 100%;
    margin-left: 0px;
    img {
      width: 100%;
      margin-left: 0;
      margin-top: 0;
    }
  }
`
export const tabImage = css`
  margin-top: 15px;
`

export const Pane = styled.div``
