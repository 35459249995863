import React, { Component } from 'react'
import Slider from 'react-rangeslider'
import { CircularProgress } from '@material-ui/core'
import Button from './LargeButtonSite'
import { last } from 'lodash'

import http, { currentAppPath } from '../utils/http'

import {
  rangeWrapper
} from './range/styles/Range.styles'

import {
  priceDescription,
  btnSignIt
} from './styles/Pricing.styles'

export function withProductPlans (WrappedComponent) {
  class ProductPlansHOC extends Component {
    state = {
      plans: [],
      loading: true,
      error: false
    }

    async componentDidMount () {
      try {
        const response = await http
          .get('/product_plans', {
            params: {
              user_application_ids: 17,
              options: 'credit'
            }
          })
          .then(res => res.data.data)
        this.setState({ plans: response, loading: false })
      } catch (e) {
        console.error(e)
        this.setState({ error: true, loading: false })
      }
    }

    render () {
      const {
        loading,
        plans,
        error
      } = this.state

      if (loading) {
        return (
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CircularProgress />
          </div>
        )
      }

      if (error) {
        return (
          <p>Planos indisponíveis.</p>
        )
      }

      return (
        <WrappedComponent
          plans={plans}
          {...this.props}
        />
      )
    }
  }

  return ProductPlansHOC
}

class PricingRangeSite extends Component {
  state = {
    current: null
  }

  componentDidMount () {
    if (this.props.plans.length > 0) this.handleOnChange(this.sliderConfigs().min)
  }

  render () {
    const { current } = this.state

    if (this.props.plans.length === 0) return <p>Não foi possível carregar os planos de créditos.</p>

    return (
      <>
        <div css={priceDescription}>{current} <span>Créditos pelo valor de</span> R$ {this.currentPlan().price}</div>
        <div
          css={rangeWrapper}
        >
          <Slider
            min={this.sliderConfigs().min}
            max={this.sliderConfigs().max}
            step={100}
            value={current}
            labels={this.legendLabels()}
            tooltip={false}
            orientation='horizontal'
            onChange={this.handleOnChange}
          />
        </div>
        <div css={btnSignIt}>
          <a
            href={`${currentAppPath}/cart?add_to_cart=${this.currentPlan().value}`}
          >
            <Button>Assine Agora</Button>
          </a>
        </div>
      </>
    )
  }

  handleOnChange = (value) => {
    this.setState({
      current: value
    })
  }

  sliderConfigs = () => {
    const sortedPlans = this.props.plans.sort((a, b) => a.value - b.value)

    return {
      max: last(sortedPlans).value,
      min: sortedPlans[0].value
    }
  }

  legendLabels = () => {
    return this.props.plans
      .map(plan => ({ [plan.value]: plan.value }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {})
  }

  currentPlan = () => {
    const {
      plans
    } = this.props

    const {
      current
    } = this.state

    const currentOrMin = current || this.sliderConfigs().min

    return plans
      .map(plan => ({ [plan.value]: { ...plan, price: Number(plan.price).toFixed(2) } }))
      .reduce((acc, curr) => ({ ...acc, ...curr }), {})[currentOrMin]
  }
}

export default withProductPlans(PricingRangeSite)
